import Vue from 'vue'
import VueMq from 'vue-mq'
import { breakpoint } from '@exporo/educts-styleguide'

Vue.use(VueMq, {
  breakpoints: {
    mobile: breakpoint.small,
    tablet: breakpoint.medium,
    laptop: breakpoint.large,
    desktop: Infinity
  },
  defaultBreakpoint: 'mobile'
})
