import { Vue } from 'nuxt-property-decorator'
import { StatusType } from '@exporo/financing_project_type_orm/dist/interfaces/Status'
import { Modules } from '@exporo/financing_project_type_orm/dist/interfaces/ToggleSectionModules/ModuleGroup'

export interface Store {
  isBottomSheetOpen: boolean
  currentModules: Modules[] | null
  modulesTitle: string | null
  currentProjectStatusType: StatusType | null
  legalType: 'ExporoInvestmentGmbH' | 'ExporoAG'
}

export const store: Store = Vue.observable({
  isBottomSheetOpen: false,
  currentModules: null,
  modulesTitle: null,
  currentProjectStatusType: null,
  legalType: 'ExporoAG'
})

export const mutations = {
  openBottomSheet(modules: Modules[], modulesTitle: string) {
    store.isBottomSheetOpen = true
    store.modulesTitle = modulesTitle
    store.currentModules = modules
  },
  closeBottomSheet() {
    store.isBottomSheetOpen = false
    store.modulesTitle = null
    store.currentModules = null
  },
  setCurrentProjectStatusType(statusType: StatusType | null) {
    store.currentProjectStatusType = statusType
  },
  setLegalType(legalType: 'Anleihe' | 'Vermögensanlage') {
    store.legalType =
      legalType === 'Anleihe' ? 'ExporoInvestmentGmbH' : 'ExporoAG'
  }
}
