import ProjectMetaData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectMetaData'
import ProjectData from '@exporo/financing_project_type_orm/dist/interfaces/ProjectData'

interface TrackingHelperData {
  singleProjectMetaData?: ProjectMetaData | null
  singleProjectData?: ProjectData | null
}

export default class TrackingHelper {
  private static instance: TrackingHelper
  private gtm: any
  private singleProjectMetaData: ProjectMetaData | null = null
  private singleProjectData: ProjectData | null = null
  private projectType: string | null = null

  static getInstance(data: TrackingHelperData, gtm?: any): TrackingHelper {
    if (!TrackingHelper.instance) {
      TrackingHelper.instance = new TrackingHelper()
      TrackingHelper.instance.gtm = gtm
    }

    TrackingHelper.instance.setData(data)

    return this.instance
  }

  private setData(data: TrackingHelperData) {
    this.singleProjectMetaData = data.singleProjectMetaData ?? null
    this.singleProjectData = data.singleProjectData ?? null

    this.projectType = this.singleProjectMetaData?.financingCheckoutSlug
      ? this.singleProjectMetaData.financingCheckoutSlug.includes('/loan/')
        ? 'loan'
        : 'bond'
      : null
  }

  public trackImpression() {
    this.gtm.push({
      event: 'productImpressions',
      ecommerce: {
        impressions: [
          {
            name: this.singleProjectMetaData?.title ?? null,
            id: this.singleProjectMetaData?.financingEntityId ?? null,
            brand: 'Exporo DE',
            variant: this.projectType,
            list: this.projectType,
            position: 1
          }
        ]
      }
    })
  }

  public trackClick() {
    this.gtm.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: {
            list: this.projectType
          },
          products: [
            {
              id: this.singleProjectMetaData?.financingEntityId ?? null,
              name: this.singleProjectMetaData?.title ?? null,
              brand: 'Exporo DE',
              variant: this.projectType,
              position: 1
            }
          ]
        }
      }
    })
  }

  public trackAddToCart() {
    this.gtm.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [
            {
              id: this.singleProjectMetaData?.contractId ?? null,
              name: this.singleProjectMetaData?.title ?? null,
              brand:
                'Exporo ' +
                (
                  window.location.hostname.split('.').reverse()[0] || 'de'
                ).toUpperCase(),
              variant: this.projectType,
              quantity: '1'
            }
          ]
        }
      }
    })
  }

  public trackProductDetail() {
    const url = this.singleProjectData!.data!.stage!.cardData!
      ?.financingCheckoutSlug

    this.gtm.push({
      event: 'productDetail',
      ecommerce: {
        detail: {
          products: [
            {
              id: this.singleProjectMetaData?.contractId ?? null,
              name: this.singleProjectMetaData?.title ?? null,
              brand:
                'Exporo ' +
                (
                  window.location.hostname.split('.').reverse()[0] || 'de'
                ).toUpperCase(),
              variant: url ? (url.includes('/loan/') ? 'loan' : 'bond') : null
            }
          ]
        }
      }
    })
  }
}
