import TrackingHelper from '~/components/Tracking/TrackingHelper'

export default function({ $gtm }) {
  TrackingHelper.getInstance({}, $gtm)
  window.dataLayer = []

  $gtm.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
    appId: 'financing_exporo_de'
  })
  setTimeout(() => {
    $gtm.init('GTM-5VRQ76')
  }, 5000)
}
