import Vue from 'vue'
import EductsPlugin from '@exporo/educts'
import UserPlugin from '@exporo/educts-component-user'
import PlatformAppPlugin from '@exporo/educts-component-platform-app'

Vue.use(EductsPlugin, {
  i18n: {
    i18next: {
      fallbackLng: 'de-DE'
    }
  }
})
Vue.use(UserPlugin)
Vue.use(PlatformAppPlugin)
